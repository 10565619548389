/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #10b981;
  border-radius: 5px;
}

.coloredA {
  color: #10b981;
}
.Main {
  position: relative;
}
.HLlogo {
  max-width: 46px;
  border-radius: 50%;
  border: 4px solid #10b981;
}
.logotxt {
  color: #10b981 !important;
}
.respoGrid {
    display: grid;
    gap: .6rem;
    margin-bottom: 2rem;
}
@media (max-width: 600px) {
.respoGrid {
    grid-template-columns: repeat(1, 1fr); 
}

}

@media (min-width: 900px) {
    .respoGrid { grid-template-columns: repeat(4, 1fr); }
    .StickedMenu {
      position: sticky !important;
      top: 13vh;
     }
  }

.PairbtncenteR {
  align-items: center !important;
  display: flex;
}

  .v-responsive.v-img.ball.ball--blue {
    width: 414px;
    height: 414px;
  }
  
  .v-responsive__sizer {
    padding-bottom: 100%;
  }
  
  .image-123 {
    width: 100%;
    height: 100%;
    min-height: 250px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .ball-background .ball--1 {
    right: 42.73%;
    top: calc(var(--vh, 1vh) * 32);
    width: 900px;
    height: 900px;
    border-radius: 0.5rem;
    background: rgba(0,79,137,.6);
    opacity: .25;
    filter: blur(200px);
    animation-delay: .5s;
    animation-duration: 5.2s;
}
.ball-background .ball {
    animation: floating 5s infinite ease-in-out;
    position: fixed;
    height: auto;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}
.ball-background .ball--2 {
    left: 54.19%;
    top: calc(var(--vh, 1vh) * 30);
    width: 1300px;
    height: 1300px;
    border-radius: 0.5rem;
    background: #3300ff;
    opacity: .2;
    filter: blur(200px);
    animation-delay: 1s;
    animation-duration: 4.6s;
}
.ball-background .ball--3 {
    left: 40.22%;
    top: calc(var(--vh, 1vh) * -10);
    width: 1380px;
    height: 1380px;
    border-radius: 0.5rem;
    background: #5364ff;
    opacity: .25;
    filter: blur(100px);
    animation-delay: .7s;
}
.ball-background .ball--4 {
    width: 960px;
    height: 960px;
    top: calc(var(--vh, 1vh) * 70);
    left: 24%;
    border-radius: 0.5rem;
    background: #3300ff;
    opacity: .2;
    filter: blur(200px);
    animation-delay: .7s;
}
.ball-background .ball--5 {
    width: 1200px;
    height: 1200px;
    top: calc(var(--vh, 1vh) * -30);
    left: -40%;
    border-radius: 0.5rem;
    background: #e57bff;
    opacity: .25;
    filter: blur(300px);
    animation-delay: .5s;
}
.ball-background .ball--blue {
    top: calc(var(--vh, 1vh) * 10);
    left: 0;
    animation-duration: 3s;
    opacity: .2;
    z-index: -2;
}
.v-img__img {
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ball-background .ball--green {
width: 290px;
    height: 290px;
    top: -10%;
    right: 10%;
    animation-delay: 1s;
    animation-duration: 4.6s;
    opacity: .1;
    z-index: -2;
}
.ball-background .ball--purple {
    width: 430px;
    height: 430px;
    top: calc(var(--vh, 1vh) * 70);
    right: 10%;
    opacity: .2;
    z-index: -2 !important;
}


.sbui-input--medium {
  border: 0px !important;
  box-shadow: 0 0 0 1px rgba(249, 250, 249, 0.1) !important;
  background-color: transparent !important;

}
.sbui-input--medium:focus {
  box-shadow: 0 0 0 1px rgba(62, 207, 142, 0.479) !important;
}

.colorlinear {
color: #10B981 !important;
background-color: #ececec !important;
}
.sbui-badge {
  height: fit-content;
  align-self: center;
}

[data-joy-color-scheme="dark"] .sbui-btn--tiny {
  background-color: #10b981 !important;
  box-sizing: border-box;
  border-color: #10b981 !important;
}
[data-joy-color-scheme="dark"] .CustomGrayColor {
  background: #333 !important;
  border-color: #333 !important;
  color:#ececec !important;
}
[data-joy-color-scheme="dark"] .a2 {
  background: #10B981 !important;
  border-color: #10B981 !important;
  color:#ececec !important;
}




[data-joy-color-scheme="dark"] .JoyListItemButton-root:hover {
  background-color: #10b98170 !important;
  box-sizing: border-box;
  border-color: #10b981 !important;
}
[data-joy-color-scheme="light"] .JoyListItemButton-root:hover {
  background-color: #10b98170 !important;
  box-sizing: border-box;
  border-color: #10b981 !important;
}

[data-joy-color-scheme="light"] .sbui-btn--tiny {
  background-color: #10b981 !important;
  border-color: #10b981 !important;
  color: #ececec;
}
[data-joy-color-scheme="light"] .LightWhite {
  background-color: #ffffff; 
}


 .BoxInfo {
  padding: 0.5rem;
  border-top: 1px solid #757b8c;
 }

[data-joy-color-scheme="dark"] body { 
  background-color: #22242a;
 color:#ececec;
}
@media only screen and (max-width: 600px) {

  .v-responsive.v-img.ball.ball--blue {
    width: 350px;
    height: 350px;
}

.ball-background .ball--blue {
    top: calc(var(--vh, -1vh) * 10);
    left: calc(var(--vh, -2vh) * 10);
    animation-duration: 3s;
    opacity: .2;
    z-index: -2;
}

.ball-background .ball--green {
  width: 150px;
  height: 150px;
}

[data-joy-color-scheme="light"] .ListButton { 
 color:#ececec !important;
}
}
.AlertStyle {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sbui-checkbox {
  cursor: pointer;
  background-color: transparent !important;
}
.textbox {
  text-align: left;
}

.PairBox {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.CardTopGrid {
     align-items: center;
    padding-right: 1rem;
    display: flex
}
.sbui-checkbox-container {
  margin-top: 0.4rem;
}

[data-joy-color-scheme="dark"] .sbui-alert-title {
 color:#ececec !important;
}
[data-joy-color-scheme="dark"] .sbui-alert-description {
 color:#ececec !important;
}

[data-joy-color-scheme="light"] .sbui-alert-description {
  color: #25252d !important;
 }

[data-joy-color-scheme="dark"] .sbui-checkbox__label-container__label__span { 
 color:#10b981;
}
.sbui-checkbox {
  margin-right: 0.4rem !important;
}

.inputfocus2 > button {
  background-color: #10b981 !important;
}

[data-joy-color-scheme="dark"] button { 
 color:#ececec;
}
 
[data-joy-color-scheme="dark"] .card { 
  background-color: transparent;
  border-color: #757b8c !important;
  overflow: hidden;

}


[data-joy-color-scheme="light"] .Connectwalletbtn { 
  background-color: #10b981 !important;
 color: #ececec;
 border-color: #10b981;
}


[data-joy-color-scheme="light"] .Connectwalletbtn:hover { 
  background-color: #10b981 !important;
 color: #ececec;
 border-color: #10b981;
}
[data-joy-color-scheme="light"] .Connectwalletbtn:hover > svg { 
 fill: #ececec;
}
 

[data-joy-color-scheme="light"] body { 
  background-color: #ededf2;
  color: rgba(0,0,0,.85);
}

.Navigation {
  background-color: transparen !important;
}

.ListButton > span {
  width: 41px !important;
}

.HLlogomint {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #10b981;
}

.badgeSoon {
  background-color: #10b981 !important;
  font-weight: 500 !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
  font-size: x-small !important;
  min-height: 16px !important;
}

.header {
  backdrop-filter: blur(24px) !important;
}

a {
  text-decoration: none;
  color: unset;
}


@media only screen and (max-width: 600px) {  
  .logotxt {
    display: none !important;
  }
}

.alertGreen {
  background-color:rgb(255, 244, 229) !important;
  color: rgb(102, 60, 0) !important;
  border: none !important;
  height: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.bottomnav {
  backdrop-filter: blur(24px) !important;
  width: 100%;
  border: 1px solid;
  height: auto !important;
  max-width: 100vw;
  justify-content: center !important;
  z-index: 20;
  border-radius: 5px;
}

.Days {
  font-size: 0.6rem !important;
  width: fit-content !important;
  min-width: fit-content !important;
  background-color: #10b981 !important;
border-color: #10b981 !important;
color:#ececec !important;
box-shadow: none !important;
}
.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
  border-color: #10b981 !important;
  background-color: #10b981 !important;
}

.Connectwalletbtn {
  margin-top: .6rem !important;
  margin-bottom: .6rem !important;
  align-items: center !important;
}

.openmodalBtn {
  max-width: fit-content;
  margin-left: auto !important;
}
.LastListConnect{
  background-color: #ffffff;
  margin-top: 1rem !important;
}

.cscscscs {
  background-color: transparent !important;
    border: 0px !important;
}


.max-Imags {
  max-height: 65px;
  width: 65px;
}

.FlexBox2 {
  display: flex;
  gap: 1rem;
}

.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
  background: #ffffff no-repeat !important;
}
.card2 {
  border: 1px solid #757b8c;
  border-radius: 5px;
  overflow: hidden;
  height: 65px !important;
}

.card3 {
  border: 1px solid #757b8c;
  border-radius: 5px;
  overflow: hidden;
}

.outlinednone {
  outline: none !important;
}

.dbhl {
  width: 40px;
}

#card-description {
  align-self: center !important;
}

.Card203 {
  display: flex;
}


.walletconnect-qrcode__base {
    background-color: var(--joy-palette-background-backdrop) !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-backdrop-filter: blur(8px) !important;
    backdrop-filter: blur(8px) !important;
}

.exs {
  margin-top: 1rem;
}

.dividers {
  margin-top: 1.8rem !important;
  border-color: #4b5563 !important;
  margin-bottom: 1rem !important;
}

.pairingheading {
  font-size: 1.25rem !important;
  color: #10B981 !important;
}

.sz2ws {
  text-align-last: left !important;
}

.finish {
  color: #10B981 !important;
}


.gradient-background--blue {
  background: linear-gradient(315deg, #1b203a, #202742, #292f4f66);
  background-size: 180% 180%;
  animation: gradient-animation 30s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
 
.bgCard {
background-image: linear-gradient(to right, #00000014, #0000002e ), linear-gradient( to right, #2dea8f, #31c77f);
background-position: 100% 0%, 0px 0px, -55% 0%, 0px 0px;
background-size: contain, auto, auto, auto;
background-repeat: no-repeat, repeat, no-repeat, repeat;
}

.bgCardtxt {
color: #ececec !important;
}

 .BadgeStep {
  margin-right: 1rem;
 }

 .divList {
  margin: 1.2rem 0px !important;
 }

 .classed-top {
  margin-top: 1.2rem;
 }



 .white--txt {
  color: #ececec !important;
 }
 .green--txt {
  color: #ececec !important;
 }

 .yellow--txt {
  color: #f59e0b !important;
 }

 .btnstake {
  background-color: transparent !important;
  border: 1px solid #ececec;
  padding: 5px 15px;
  border-radius: 5px;
  color: #ececec;
  cursor: pointer;
 }



 .mainBody {
    background: rgba(0, 0, 0, 0.45) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    padding: 1rem !important;
 }

 @font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("Filson"),
    url("./Fonts/Filson.ttf") format("truetype");
}
 
 

.JoyCircularProgress-progress {
  stroke: #ececec !important;
}
.JoyCircularProgress-track {
  stroke: #ffffff29 !important;
}
 .blue-bg {
  opacity: .45;
  background-image: url(./images/hl2.png),linear-gradient(to right, #00000014, #0000002e ), linear-gradient( to right, #2c3e50, #4ca1af);
  background-position: 100% 0%, 0px 0px, -55% 0%, 0px 0px;
  background-size: contain, auto, auto, auto;
  background-repeat: no-repeat, repeat, no-repeat, repeat;
 }

 .gray-bg {
  opacity: .45;
  background-image: url(./images/hl1.png),linear-gradient(to right, #00000014, #0000002e ), linear-gradient( to right, #333333, #393939);
  background-position: 100% 0%, 0px 0px, -55% 0%, 0px 0px;
  background-size: contain, auto, auto, auto;
  background-repeat: no-repeat, repeat, no-repeat, repeat;
 }

 .red-bg {
  opacity: 1;
  background-position: 100% 0%, 0px 0px, -55% 0%, 0px 0px;
  background-size: contain, auto, auto, auto;
  background-repeat: no-repeat, repeat, no-repeat, repeat;
  background-image: url(./images/logo-HL.png), linear-gradient(to right, #F45C43, #EB3349); 
 }
 

 .LogoBox {
  margin-bottom: -5px;
 }

 .AlertTopBar {
  border-radius: 0 !important;
  background-color: #10B981 !important;
  color: #ececec !important;
  padding-top: 2px !important;
 }
 .AlertTopBarTxt {
  color: #ececec !important;
 }


.Joy-disabled {
  color: #757b8c !important;
 }
 .centerProgress {
  align-self: center;
  text-align: center;
}
 @media (min-width: 600px) {
.v-col-sm-4 {
    flex: 0 0 33.3333333333% !important;
    max-width: 33.3333333333% !important;
}
 }

 

.v-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
 .v-img__img--cover {
  object-fit: cover;
}

.v-btn.btn-stake, .v-btn.btn-quest {
  display: flex;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  border-radius: 30px;
  color: #fff;
  background: linear-gradient(128.38deg,#3431ea 0%,#6d39ff 100%);
  border-color: transparent;
}

.v-btn.short, .v-btn.btn-stake, .v-btn.btn-quest {
  height: 32px;
  font-size: .75rem;
}
.v-btn.v-btn--density-default {
  height: calc(var(--v-btn-height) + 0px);
}
.btn-contain {
  max-width: 116px;
}

.base-banner__content {
width: 100% !important;
padding: 20px;
position: relative;
z-index: 2;
color: #fff;
white-space: pre-wrap;
line-height: 2;
}

.base-banner__content2 {
  width: 100% !important;
  padding: 20px;
  position: relative;
  z-index: 2;
  white-space: pre-wrap;
  line-height: 2;
  }

.base-banner__background .v-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.v-responsive {
  display: flex;
  flex: 1 0 auto;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.v-img__img, .v-img__picture, .v-img__gradient, .v-img__placeholder, .v-img__error {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
 

.ball {
  overflow: visible !important;
}

@media (min-width: 1280px) {
.v-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
}
@media (min-width: 960px) {
.v-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
}
 
@media (min-width: 600px) {
  .subtitle {
    max-width: 100%;
  }
.v-col-sm-8 {
    flex: 0 0 66.6666666667% !important;
    max-width: 66.6666666667% !important;
}
}
.v-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}


@media (min-width: 1323px) {
.v-col-sm-12 {
    flex: 0 0 50% !important;
    max-width: 100%;
}
}
.v-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 12px;
}

.v-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  margin: -12px;
}



.glassMorph {
  background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(7.1px);
}

.base-banner {
    position: relative;
        width: 100%;
    overflow: hidden;
    align-items: center;
    align-content: center;
    font-size: .875rem;
}
@media (min-width: 600px) {
.justify-sm-space-between {
    justify-content: space-between!important;
}
}
@media (min-width: 600px) {
.flex-sm-row-reverse {
    flex-direction: row-reverse!important;
}
}

.justify-center {
    justify-content: center!important;
}
.flex-column {
    flex-direction: column!important;
}
.d-flex {
    display: flex!important;
}
.justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.rounded-2xl {
    border-radius: 1rem;
}

.mobileFlexeds {
  display: flex;
}


.centerdbtn--2 {
text-align: center;
    text-align: -webkit-center;
    margin-block-end: 0.83em;
}

.PairedNo {
  margin-left: auto;
  margin-right: auto;
}

.margined--3 {
  padding-top: 30px;
}

.ball-background {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
}


.ball-background .io-bg-2 {
  background: #11b186;
  z-index: -2 !important;
}
.ball-background .io-bg-3 {
  background: #073227;
  z-index: -2 !important;
  left: 40.22%;
  top: calc(var(--vh, 1vh) * -10);
  width: 1380px;
  height: 1380px;
  border-radius: 50%;
  opacity: .15;
  filter: blur(100px);
  animation-delay: .7s;
}

 

 .bbb-ins {
  width: 100%;
  height: 100%;
  border-radius: 30px;
 }